<template>
  <div>
    <el-table :data="qutaForm.wagesList" border :summary-method="getSummaries" show-summary style="width: 100%; margin-top: 20px">
      <el-table-column prop="name" width="180"> </el-table-column>
      <el-table-column label="成本价">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'wagesList.' + scope.$index + '.cost_price'">
            <el-input
              disabled
              maxlength="11"
              v-model="qutaForm.wagesList[scope.$index].cost_price"
              show-word-limit
              placeholder="暂无成本价"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="税点">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'wagesList.' + scope.$index + '.tax_point'">
            <el-input
              @change="taxPointChange(scope)"
              maxlength="11"
              v-model="qutaForm.wagesList[scope.$index].tax_point"
              show-word-limit
              placeholder="暂无税点"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="含税金额">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'wagesList.' + scope.$index + '.tax_price'">
            <el-input
              disabled
              maxlength="11"
              v-model="qutaForm.wagesList[scope.$index].tax_price"
              show-word-limit
              placeholder="暂无含税金额"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="税额合计">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'wagesList.' + scope.$index + '.tax_toatl'">
            <el-input
              disabled
              maxlength="11"
              v-model="qutaForm.wagesList[scope.$index].tax_toatl"
              show-word-limit
              placeholder="暂无税额合计"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { BigNumber } from 'bignumber.js';
export default {
  name: 'QutaParameter',
  props: {
    qutaForm: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      wegesForm: {}
    };
  },
  methods: {
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      let totalList = ['合计', '', '', '', ''];
      columns.forEach((column, index) => {
        let datas = data;
        let totalA = new BigNumber(0);
        let totalB = new BigNumber(0);
        let totalC = new BigNumber(0);
        for (let i in datas) {
          totalA = totalA.plus(datas[i].cost_price ? datas[i].cost_price : 0);
          totalB = totalB.plus(datas[i].tax_price ? datas[i].tax_price : 0);
          totalC = totalC.plus(datas[i].tax_toatl ? datas[i].tax_toatl : 0);
        }
        totalA = totalA.toNumber();
        totalB = totalB.toNumber();
        totalC = totalC.toNumber();
        this.wegesForm.totalTwo = totalA;
        this.wegesForm.totalFour = totalB;
        this.wegesForm.totalFive = totalC;
        this.$emit('taxPointChangeTotal', this.wegesForm);
        totalList[1] = this.helper.haveFour(totalA);
        totalList[3] = this.helper.haveFour(totalB);
        totalList[4] = this.helper.haveFour(totalC);
      });
      return totalList;
    },
    // 税点变化
    taxPointChange(val) {
      this.$emit('taxPointChange', val.$index);
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .el-table__footer-wrapper {
  font-size: 1.17em;
  font-weight: 900;
}
::v-deep .el-table__footer-wrapper .el-table_1_column_2 {
  color: #c70e0e;
}
::v-deep .el-table__footer-wrapper .el-table_1_column_4 {
  color: #c70e0e;
}

::v-deep .el-table__footer-wrapper .el-table_1_column_5 {
  color: #c70e0e;
}
</style>
