<template>
  <div>
    <el-table :data="qutaForm.quta_sala_maps" border style="width: 100%; margin-top: 20px">
      <el-table-column label="序号" width="180">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="工序" prop="name" width="180"> </el-table-column>
      <el-table-column label="数值">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'quta_sala_maps.' + scope.$index + '.time'">
            <el-input
              maxlength="11"
              disabled
              v-model="qutaForm.quta_sala_maps[scope.$index].time"
              @input="qutaForm.quta_sala_maps[scope.$index].time = helper.keepTNum1(qutaForm.quta_sala_maps[scope.$index].time)"
              @blur="qutaForm.quta_sala_maps[scope.$index].time = helper.calcPrice(qutaForm.quta_sala_maps[scope.$index].time, 4, 1000000)"
              show-word-limit
              placeholder="暂无样品间报价"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="单位" prop="unit"> </el-table-column>
      <el-table-column label="数值调整范围(可填负数)">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'quta_sala_maps.' + scope.$index + '.time_range'">
            <el-input
              @change="salaChange"
              maxlength="11"
              v-model="qutaForm.quta_sala_maps[scope.$index].time_range"
              @input="
                qutaForm.quta_sala_maps[scope.$index].time_range = helper.keepTNumNega(qutaForm.quta_sala_maps[scope.$index].time_range)
              "
              show-word-limit
              placeholder="暂无时间调整范围"
            >
              <template slot="append">
                <span v-if="scope.$index === 0">片</span>
                <span v-else>秒</span>
              </template>
            </el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="单价(分钟/元)">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'quta_sala_maps.' + scope.$index + '.sala'">
            <el-input
              @change="salaChange"
              maxlength="11"
              v-model="qutaForm.quta_sala_maps[scope.$index].sala"
              @input="qutaForm.quta_sala_maps[scope.$index].sala = helper.keepTNum1(qutaForm.quta_sala_maps[scope.$index].sala)"
              @blur="qutaForm.quta_sala_maps[scope.$index].sala = helper.calcPrice(qutaForm.quta_sala_maps[scope.$index].sala, 4, 1000000)"
              show-word-limit
              placeholder="暂无样品间报价"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="合计">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'quta_sala_maps.' + scope.$index + '.total'">
            <el-input
              disabled
              maxlength="11"
              v-model="qutaForm.quta_sala_maps[scope.$index].total"
              show-word-limit
              placeholder="暂无样品间报价"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
    </el-table>
    <div class="vd_price_toatl">
      <div class="vd_price">
        <h3><span>总计:</span></h3>
        <h2 class="vg_ml_8" style="color: #c70e0e">
          <span>{{ totalPrice }}</span>
        </h2>
      </div>
    </div>
  </div>
</template>

<script>
import { BigNumber } from 'bignumber.js';
export default {
  name: 'QutaOffer',
  props: {
    qutaForm: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      totalPrice: '0.0000'
    };
  },
  methods: {
    // 单价变化
    salaChange() {
      this.$emit('salaChange', this.total);
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .el-table__footer-wrapper {
  font-size: 1.17em;
  font-weight: 900;
}
::v-deep .el-table__footer-wrapper .el-table_5_column_36 {
  color: #c70e0e;
}
::v-deep .vd_inputRight {
  > input {
    text-align: right;
  }
}
.vd_price_toatl {
  /* display: flex;
  justify-content: space-between; */
  padding: 10px 20px;
}

.vd_price {
  display: flex;
  justify-content: flex-end;
}
</style>
