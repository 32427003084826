<template>
  <div>
    <!-- 原材料  -->
    <div>
      <el-button type="primary" size="small" plain @click="clickImport">导入</el-button>
      <el-button type="danger" size="small" plain @click="clickDel">删除</el-button>
    </div>
    <el-table :data="qutaForm.quta_mtrl_list" style="width: 100%" @selection-change="selectChange" class="vg_mt_16"
              border>
      <el-table-column type="selection" align="center"></el-table-column>
      <el-table-column type="index" label="序号" align="center"></el-table-column>
      <el-table-column label="物料编号" align="center" prop="mtrl_no" min-width="120">
        <template slot-scope="scope">
          <el-form-item
              label-width="0"
              :prop="'quta_mtrl_list.' + scope.$index + '.mtrl_no'"
              :rules="{ required: true, message: ' ', trigger: 'blur' }"
          >
            <el-input
                v-model.trim="qutaForm.quta_mtrl_list[scope.$index].mtrl_no"
                clearable
                maxlength="30"
                disabled
                show-word-limit
                size="small"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="物料类型" align="center" prop="mtrl_type" min-width="100">
        <template slot-scope="scope">
          <el-form-item
              label-width="0"
              :prop="'quta_mtrl_list.' + scope.$index + '.mtrl_type'"
              :rules="{ required: true, message: ' ', trigger: 'blur' }"
          >
            <el-select v-model="qutaForm.quta_mtrl_list[scope.$index].mtrl_type" size="small" disabled>
              <el-option v-for="item in typeList" :key="item.value" :value="item.value" :label="item.label"></el-option>
            </el-select>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="物料名称" align="center" prop="mtrl_name" min-width="120">
        <template slot-scope="scope">
          <el-form-item
              label-width="0"
              :prop="'quta_mtrl_list.' + scope.$index + '.mtrl_name'"
              :rules="{ required: true, message: ' ', trigger: 'blur' }"
          >
            <el-tooltip class="item" effect="dark" :content="scope.row.mtrl_name" placement="top-start">
              <el-input
                  v-model.trim="qutaForm.quta_mtrl_list[scope.$index].mtrl_name"
                  clearable
                  maxlength="30"
                  disabled
                  show-word-limit
                  size="small"
              ></el-input>
            </el-tooltip>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="物料规格" align="center" prop="mtrl_spec" min-width="120">
        <template slot="header">
          <span style="color: red">物料规格</span>
        </template>
        <template slot-scope="scope">
          <el-form-item
              :prop="'quta_mtrl_list.' + scope.$index + '.mtrl_spec'"
              label-width="0"
              :rules="{ required: true, message: ' ', trigger: 'blur' }"
          >
            <el-tooltip class="item" effect="dark" :content="scope.row.mtrl_spec" placement="top-start">
              <el-input
                  size="small"
                  v-model="qutaForm.quta_mtrl_list[scope.$index].mtrl_spec"
                  type="textarea"
                  clearable
                  :autosize="{ minRows: 1, maxRows: 4 }"
                  maxlength="50"
                  show-word-limit
              ></el-input>
            </el-tooltip>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="部位" align="center" prop="quta_mtrl_part" min-width="150">
        <template #header>
          <span style="color: red">部位</span>
        </template>
        <el-table-column align="center" min-width="120">
          <template #header>
            <el-button-group>
              <el-button size="mini" @click="setColor(0)">黑色</el-button>
              <el-button size="mini" type="danger" @click="setColor(1)">红色</el-button>
            </el-button-group>
          </template>
          <template v-slot="scope">
            <el-form-item
                :prop="'quta_mtrl_list.' + scope.$index + '.quta_mtrl_part'"
                label-width="0"
                :rules="{ required: true, message: ' ', trigger: 'blur' }"
            >
              <el-tooltip effect="dark" :content="scope.row.quta_mtrl_part" placement="top-start">
                <el-input
                    :class="qutaForm.quta_mtrl_list[scope.$index].is_red === 1 ? 'quta' : ''"
                    size="small"
                    v-model="qutaForm.quta_mtrl_list[scope.$index].quta_mtrl_part"
                    clearable
                    @focus="e => setColorIndex(e, scope.$index)"
                    maxlength="50"
                    type="textarea"
                    show-word-limit
                    :autosize="{ minRows: 1, maxRows: 4 }"
                />
              </el-tooltip>
            </el-form-item>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="用量" align="center" prop="quta_mtrl_consume" min-width="140">
        <template slot="header">
          <span style="color: red">用量</span>
        </template>
        <template slot-scope="scope">
          <el-form-item
              :prop="'quta_mtrl_list.' + scope.$index + '.quta_mtrl_consume'"
              label-width="0"
              :rules="{ required: true, message: ' ', trigger: 'blur' }"
          >
            <el-input
                size="small"
                v-model="qutaForm.quta_mtrl_list[scope.$index].quta_mtrl_consume"
                maxlength="15"
                show-word-limit
                @change="() => getTotalPrice(999)"
                @input="
                qutaForm.quta_mtrl_list[scope.$index].quta_mtrl_consume =
                  qutaForm.quta_mtrl_list[scope.$index].quta_mtrl_consume
                    .replace(/^0+(\d)/, '$1')
                    .replace(/^\./, '0.')
                    .match(/^\d*(\.?\d{0,4})/g)[0] || ''
              "
                @blur="
                qutaForm.quta_mtrl_list[scope.$index].quta_mtrl_consume = helper.calcPrice(
                  qutaForm.quta_mtrl_list[scope.$index].quta_mtrl_consume,
                  4,
                  10000000000
                )
              "
            >
            </el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="损耗比率" align="center" prop="quta_mtrl_lossrate" min-width="140">
        <template slot="header">
          <span style="color: red">损耗比率</span>
        </template>
        <template slot-scope="scope">
          <el-form-item
              :prop="'quta_mtrl_list.' + scope.$index + '.quta_mtrl_lossrate'"
              label-width="0"
              :rules="{ required: true, message: ' ', trigger: 'blur' }"
          >
            <el-input
                size="small"
                v-model="qutaForm.quta_mtrl_list[scope.$index].quta_mtrl_lossrate"
                maxlength="15"
                show-word-limit
                @change="() => getTotalPrice(999)"
                @input="
                qutaForm.quta_mtrl_list[scope.$index].quta_mtrl_lossrate =
                  qutaForm.quta_mtrl_list[scope.$index].quta_mtrl_lossrate
                    .replace(/^0+(\d)/, '$1')
                    .replace(/^\./, '0.')
                    .match(/^\d*(\.?\d{0,4})/g)[0] || ''
              "
                @blur="
                qutaForm.quta_mtrl_list[scope.$index].quta_mtrl_lossrate = helper.calcPrice(
                  qutaForm.quta_mtrl_list[scope.$index].quta_mtrl_lossrate,
                  4,
                  10000000000
                )
              "
            >
            </el-input>
          </el-form-item>
        </template>
      </el-table-column>

      <el-table-column label="含损耗用量" align="center" prop="quta_contain_lossrate" min-width="140">
        <template slot="header">
          <span style="color: red">含损耗用量</span>
        </template>
        <template slot-scope="scope">
          <el-form-item
              :prop="'quta_mtrl_list.' + scope.$index + '.quta_contain_lossrate'"
              label-width="0"
              :rules="{ required: true, message: ' ', trigger: 'blur' }"
          >
            <el-input
                size="small"
                v-model="qutaForm.quta_mtrl_list[scope.$index].quta_contain_lossrate"
                maxlength="15"
                disabled
                show-word-limit
            >
            </el-input>
          </el-form-item>
        </template>
      </el-table-column>

      <el-table-column label="单价" align="center" prop="mtrl_price" min-width="140">
        <template slot="header">
          <span style="color: red">单价</span>
        </template>
        <template slot-scope="scope">
          <el-form-item
              :prop="'quta_mtrl_list.' + scope.$index + '.mtrl_price'"
              label-width="0"
              :rules="{ required: true, message: ' ', trigger: 'blur' }"
          >
            <el-input
                size="small"
                v-model="qutaForm.quta_mtrl_list[scope.$index].mtrl_price"
                maxlength="15"
                show-word-limit
                @change="getTotalPrice"
                @input="
                qutaForm.quta_mtrl_list[scope.$index].mtrl_price =
                  qutaForm.quta_mtrl_list[scope.$index].mtrl_price
                    .replace(/^0+(\d)/, '$1')
                    .replace(/^\./, '0.')
                    .match(/^\d*(\.?\d{0,4})/g)[0] || ''
              "
                @blur="
                qutaForm.quta_mtrl_list[scope.$index].mtrl_price = helper.calcPrice(
                  qutaForm.quta_mtrl_list[scope.$index].mtrl_price,
                  4,
                  10000000000
                )
              "
            >
            </el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="工艺费用" align="center" show-overflow-tooltip min-width="140">
        <template slot="header">
          <span style="color: red">工艺费用</span>
        </template>
        <template slot-scope="scope">
          <!-- <el-form-item label-width="0">
            <el-input v-model="qutaForm.quta_mtrl_list[scope.$index].mtrl_addcost" disabled size="small"></el-input>
          </el-form-item> -->
          <el-form-item label-width="0">
            <el-row>
              <el-col :md="12"><span>复合费用</span></el-col>
              <el-col :md="12">
                <el-input
                    disabled
                    v-model="qutaForm.quta_mtrl_list[scope.$index].mtrl_comp_addcost"
                    show-word-limit
                    placeholder="暂无复合费用"
                ></el-input>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label-width="0">
            <el-row>
              <el-col :md="12"><span>印花费用</span></el-col>
              <el-col :md="12">
                <el-input
                    disabled
                    v-model="qutaForm.quta_mtrl_list[scope.$index].mtrl_prit_addcost"
                    show-word-limit
                    placeholder="暂无印花费用"
                ></el-input>
              </el-col>
            </el-row>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="金额" align="right" show-overflow-tooltip min-width="120">
        <template slot-scope="scope">
          <span class="vg_mr_8">
            {{
              helper.haveFour(
                  (Number(scope.row.mtrl_prit_addcost) + Number(scope.row.mtrl_comp_addcost) + Number(scope.row.mtrl_price)) *
                  Number(scope.row.quta_contain_lossrate)
              )
            }}
          </span>
          <!-- 含损耗用量 * 损耗百分比 + 额外费用   @-->
        </template>
      </el-table-column>
    </el-table>

    <div class="vd_price_toatl">
      <div class="vd_price">
        <h3><span>总计:</span></h3>
        <h2 class="vg_ml_8" style="color: #c70e0e">
          <span>{{ totalPrice }}</span>
        </h2>
      </div>
    </div>

    <el-dialog title="提示" :visible.sync="dialogVisible" width="70%">
      <requImport @childChange="childChange" :qutaForm="qutaForm"/>
    </el-dialog>
  </div>
</template>

<script>
import requImport from './requImport2.vue';
import {BigNumber} from 'bignumber.js';

export default {
  name: 'QutaRawMater',
  components: {
    requImport
  },
  props: {
    qutaForm: {
      type: Object,
      required: true,
      default() {
        return {
          quta_mtrl_list: []
        };
      }
    }
  },
  data() {
    return {
      mulTab: [],
      total: 0,
      dialogVisible: false,
      typeList: [
        {value: 0, label: '原面料'},
        {value: 3, label: '加工面料'}
      ],
      totalPrice: 0,
      colorIndex: 0
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
    },
    selectChange(selection) {
      this.mulTab = selection;
    },
    clickImport() {
      this.dialogVisible = true;
    },
    clickDel() {
      if (this.mulTab.length === 0) {
        return this.$message.warning('至少选择一条数据');
      }
      let temp = [];
      for (let i = 0; i < this.mulTab.length; i++) {
        let ind = null;
        ind = this.qutaForm.quta_mtrl_list.indexOf(this.mulTab[i]);
        temp.push(ind);
      }
      temp = temp.sort((a, b) => {
        return b - a;
      });
      for (let j of temp) {
        this.qutaForm.quta_mtrl_list.splice(j, 1);
      }
      this.getTotalPrice();
    },
    childChange(selection) {
      selection.forEach(item => {
        item.mtrl_price = this.helper.haveFour(item.mtrl_prod_price, 4);
        let smpl_mtrl_addcost = item.smpl_mtrl_addcost ? item.smpl_mtrl_addcost : 0;
        console.log('smpl_mtrl_addcost', smpl_mtrl_addcost);
        this.$set(item, 'quta_mtrl_part', ''); //部位
        this.$set(item, 'quta_contain_lossrate', '');
        this.$set(item, 'quta_mtrl_lossrate', item.smpl_mtrl_loss); //损耗百分比
        this.$set(item, 'quta_mtrl_consume', this.helper.haveFour(item.smpl_mtrl_consume, 4)); //用量
        this.$set(item, 'mtrl_addcost', this.helper.haveFour(smpl_mtrl_addcost)); //额外费用
        this.$set(item, 'mtrl_prit_addcost', this.helper.haveFour(smpl_mtrl_addcost)); //额外费用
        this.$set(item, 'mtrl_comp_addcost', this.helper.haveFour(smpl_mtrl_addcost)); //额外费用
        this.qutaForm.quta_mtrl_list.push(item);
      });
      this.dialogVisible = false;
    },
    getTotalPrice(flag) {
      if (flag === 999) {
        this.qutaForm.quta_mtrl_list.forEach(item => {
          item.quta_contain_lossrate = this.helper.haveFour(item.quta_mtrl_lossrate * item.quta_mtrl_consume, 4);
        });
      }
      setTimeout(() => {
        let num = new BigNumber(0);
        if (this.qutaForm.quta_mtrl_list) {
          this.qutaForm.quta_mtrl_list.forEach(item => {
            let num1 = new BigNumber(0);
            num1 = num1.plus(item.mtrl_comp_addcost).plus(item.mtrl_prit_addcost).plus(item.mtrl_price);
            num1 = num1.times(item.quta_contain_lossrate).toNumber();
            let num2 = this.helper.haveFour(num1);
            num = num.plus(num2);
          });
        }
        num = num.toNumber();
        this.$emit('getTotalPriceTo', num);
        this.totalPrice = this.helper.haveFour(num, 4);
      }, 500);
    },
    setColor(val) {
      this.qutaForm.quta_mtrl_list[this.colorIndex].is_red = val;
    },
    setColorIndex(e, index) {
      this.colorIndex = index;
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .quta {
  .el-textarea__inner {
    color: red;
  }
}

.vd_price_toatl {
  /* display: flex;
  justify-content: space-between; */
  padding: 10px 20px;
}

.vd_price {
  display: flex;
  justify-content: flex-end;
}
</style>
