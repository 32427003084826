<template>
  <div>
    <el-row class="vg_mb_8">
      <el-col :md="6">
        <el-form-item label-width="0" prop="quta_stam_num" :rules="{ required: true, message: ' ', trigger: 'blur' }">
          <!-- <div class="vd_dis"> -->
          <el-row>
            <el-col :md="12" class="vd_lab_wid">
              <span>印片片数:</span>
            </el-col>
            <el-col :md="12">
              <el-input
                @change="printingFilmPriceChange"
                maxlength="10"
                size="small"
                v-model="qutaForm.quta_stam_num"
                @input="qutaForm.quta_stam_num = helper.keepTNum(qutaForm.quta_stam_num)"
              ></el-input>
            </el-col>
          </el-row>
          <!-- </div> -->
        </el-form-item>
      </el-col>
      <el-col :md="6">
        <el-form-item label-width="0" prop="quta_stam_pru" :rules="{ required: true, message: ' ', trigger: 'blur' }">
          <el-row>
            <el-col :md="12" class="vd_lab_wid">
              <span>印片单价:</span>
            </el-col>
            <el-col :md="12">
              <el-input
                @change="printingFilmPriceChange"
                size="small"
                v-model="qutaForm.quta_stam_pru"
                @input="qutaForm.quta_stam_pru = helper.keepEngNum4(qutaForm.quta_stam_pru)"
                @blur="qutaForm.quta_stam_pru = helper.calcPrice(qutaForm.quta_stam_pru, 4, 10000)"
              ></el-input>
            </el-col>
          </el-row>
        </el-form-item>
      </el-col>
      <el-col :md="6">
        <el-form-item label-width="0">
          <el-row>
            <el-col :md="12" class="vd_lab_wid">
              <span
                >印片金额:
                <el-popover placement="top-start" width="200" trigger="hover" content="	金额=单价*用量">
                  <i slot="reference" class="el-icon-warning-outline"></i>
                </el-popover>
              </span>
            </el-col>
            <el-col :md="12">
              <el-input disabled size="small" v-model="printingFilmPrice"></el-input>
            </el-col>
          </el-row>
        </el-form-item>
      </el-col>
      <el-col :md="6">
        <el-form-item label-width="0" prop="quta_ctn_prsm" :rules="{ required: true, message: ' ', trigger: 'blur' }">
          <el-row>
            <el-col :md="12" class="vd_lab_wid">
              <span>纸箱平方单价:</span>
            </el-col>
            <el-col :md="12">
              <el-input
                @change="cartonPriceChange"
                size="small"
                v-model="qutaForm.quta_ctn_prsm"
                @input="qutaForm.quta_ctn_prsm = helper.keepEngNum4(qutaForm.quta_ctn_prsm)"
                @blur="qutaForm.quta_ctn_prsm = helper.calcPrice(qutaForm.quta_ctn_prsm, 4, 10000)"
              ></el-input>
            </el-col>
          </el-row>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row class="vg_mb_8">
      <el-col :md="6">
        <el-form-item label-width="0" :rules="{ required: true, message: ' ', trigger: 'blur' }">
          <el-row>
            <el-col :md="12" class="vd_lab_wid">
              <span
                >纸箱金额:
                <el-popover
                  placement="top-start"
                  width="200"
                  trigger="hover"
                  content="金额=(长+宽+0.08) *（宽+高+0.04) * 纸箱单价 / 装箱数"
                >
                  <i slot="reference" class="el-icon-warning-outline"></i>
                </el-popover>
              </span>
            </el-col>
            <el-col :md="12">
              <el-input disabled size="small" v-model="cartonPrice"></el-input>
            </el-col>
          </el-row>
        </el-form-item>
      </el-col>
      <el-col :md="6">
        <el-form-item label-width="0" prop="quta_bag_prkg" :rules="{ required: true, message: ' ', trigger: 'blur' }">
          <el-row>
            <el-col :md="12" class="vd_lab_wid">
              <span>塑料袋公斤单价:</span>
            </el-col>
            <el-col :md="12">
              <el-input
                @change="emcdNumPriceChange"
                size="small"
                v-model="qutaForm.quta_bag_prkg"
                @input="qutaForm.quta_bag_prkg = helper.keepEngNum4(qutaForm.quta_bag_prkg)"
                @blur="qutaForm.quta_bag_prkg = helper.calcPrice(qutaForm.quta_bag_prkg, 4, 10000)"
              ></el-input>
            </el-col>
          </el-row>
        </el-form-item>
      </el-col>
      <el-col :md="6">
        <el-form-item label-width="0" :rules="{ required: true, message: ' ', trigger: 'blur' }">
          <el-row>
            <el-col :md="12" class="vd_lab_wid">
              <span
                >塑料袋金额:
                <el-popover placement="top-start" width="200" trigger="hover" content="金额=（长 * 宽 * 0.08) * 塑料袋公斤单价 / 装袋数">
                  <i slot="reference" class="el-icon-warning-outline"></i>
                </el-popover>
              </span>
            </el-col>
            <el-col :md="12">
              <el-input disabled size="small" v-model="bagPrkgPrice"></el-input>
            </el-col>
          </el-row>
        </el-form-item>
      </el-col>
      <el-col :md="6">
        <el-form-item label-width="0" prop="quta_emcd_num" :rules="{ required: true, message: ' ', trigger: 'blur' }">
          <el-row>
            <el-col :md="12" class="vd_lab_wid">
              <span>电绣片数:</span>
            </el-col>
            <el-col :md="12">
              <el-input @change="emcdTotalPriceChnage" size="small" v-model="qutaForm.quta_emcd_num"></el-input>
            </el-col>
          </el-row>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row class="vg_mb_8">
      <el-col :md="6">
        <el-form-item label-width="0" prop="quta_emcd_pru" :rules="{ required: true, message: ' ', trigger: 'blur' }">
          <el-row>
            <el-col :md="12" class="vd_lab_wid">
              <span>电绣片数单价:</span>
            </el-col>
            <el-col :md="12">
              <el-input @change="emcdTotalPriceChnage" size="small" v-model="qutaForm.quta_emcd_pru"></el-input>
            </el-col>
          </el-row>
        </el-form-item>
      </el-col>
      <el-col :md="6">
        <el-form-item label-width="0" prop="quta_emcd_pnum" :rules="{ required: true, message: ' ', trigger: 'blur' }">
          <el-row>
            <el-col :md="12" class="vd_lab_wid">
              <span>电绣贴数:</span>
            </el-col>
            <el-col :md="12">
              <el-input @change="emcdTotalPriceChnage" size="small" v-model="qutaForm.quta_emcd_pnum"></el-input>
            </el-col>
          </el-row>
        </el-form-item>
      </el-col>
      <el-col :md="6">
        <el-form-item label-width="0" prop="quta_emcd_ppru" :rules="{ required: true, message: ' ', trigger: 'blur' }">
          <el-row>
            <el-col :md="12" class="vd_lab_wid">
              <span>电绣贴数单价:</span>
            </el-col>
            <el-col :md="12">
              <el-input @change="emcdTotalPriceChnage" size="small" v-model="qutaForm.quta_emcd_ppru"></el-input>
            </el-col>
          </el-row>
        </el-form-item>
      </el-col>
      <el-col :md="6">
        <el-form-item label-width="0" prop="quta_emcd_hnum" :rules="{ required: true, message: ' ', trigger: 'blur' }">
          <el-row>
            <el-col :md="12" class="vd_lab_wid">
              <span>电绣针数:</span>
            </el-col>
            <el-col :md="12">
              <el-input @change="emcdTotalPriceChnage" size="small" v-model="qutaForm.quta_emcd_hnum"></el-input>
            </el-col>
          </el-row>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row class="vg_mb_8">
      <el-col :md="6">
        <el-form-item label-width="0" prop="quta_emcd_hpru" :rules="{ required: true, message: ' ', trigger: 'blur' }">
          <el-row>
            <el-col :md="12" class="vd_lab_wid">
              <span>电绣针数单价:</span>
            </el-col>
            <el-col :md="12">
              <el-input @change="emcdTotalPriceChnage" size="small" v-model="qutaForm.quta_emcd_hpru"></el-input>
            </el-col>
          </el-row>
        </el-form-item>
      </el-col>
      <el-col :md="6">
        <el-form-item label-width="0" prop="quta_emcd_num" :rules="{ required: true, message: ' ', trigger: 'blur' }">
          <el-row>
            <el-col :md="12" class="vd_lab_wid">
              <span
                >电绣金额:
                <el-popover placement="top-start" width="200" trigger="hover" content="金额=贴布价格+(片价数和针数价最大的那个）">
                  <i slot="reference" class="el-icon-warning-outline"></i>
                </el-popover>
              </span>
            </el-col>
            <el-col :md="12">
              <el-input
                @change="getTotalPrice"
                @input="qutaForm.quta_emcd_price = helper.keepEngNum4(qutaForm.quta_emcd_price)"
                size="small"
                v-model="qutaForm.quta_emcd_price"
              ></el-input>
            </el-col>
          </el-row>
        </el-form-item>
      </el-col>
      <el-col :md="6">
        <el-form-item label-width="0" prop="smpl_freight" :rules="{ required: true, message: ' ', trigger: 'blur' }">
          <el-row>
            <el-col :md="12" class="vd_lab_wid">
              <span>运费单价:</span>
            </el-col>
            <el-col :md="12">
              <el-input
                @change="freightPriceChange"
                size="small"
                v-model="qutaForm.smpl_freight"
                @input="qutaForm.smpl_freight = helper.keepEngNum4(qutaForm.smpl_freight)"
                @blur="qutaForm.smpl_freight = helper.calcPrice(qutaForm.smpl_freight, 4, 10000)"
              ></el-input>
            </el-col>
          </el-row>
          <!-- :disabled="qutaForm.smpl_frei_flag === '0'" -->
        </el-form-item>
      </el-col>
      <el-col :md="6">
        <el-form-item label-width="0" :rules="{ required: true, message: ' ', trigger: 'blur' }">
          <el-row>
            <el-col :md="12" class="vd_lab_wid">
              <span
                >运费金额:
                <el-popover
                  placement="top-start"
                  width="200"
                  trigger="hover"
                  content="金额==(纸箱)长x宽x高x运费系数（150）/装箱数（必须含运费才可计算）"
                >
                  <i slot="reference" class="el-icon-warning-outline"></i>
                </el-popover>
              </span>
            </el-col>
            <el-col :md="12">
              <el-input disabled size="small" v-model="freightPrice"></el-input>
            </el-col>
          </el-row>
        </el-form-item>
      </el-col>
    </el-row>
    <el-button type="primary" plain size="small" @click="addQutaAccmtrl">新增</el-button>
    <el-button type="danger" size="small" plain @click="delQutaAccmtrl">删除</el-button>
    <el-table
      :data="qutaForm.quta_accmtrl_list"
      stripe
      style="width: 100%"
      class="vg_mt_16"
      border
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column type="index" width="70" label="序号" align="center"></el-table-column>
      <el-table-column label="名称" align="center" prop="mtrl_name">
        <template slot="header">
          <span style="color: red">名称</span>
        </template>
        <template slot-scope="scope">
          <el-form-item
            label-width="0"
            :prop="'quta_accmtrl_list.' + scope.$index + '.mtrl_name'"
            :rules="{ required: true, message: ' ', trigger: 'blur' }"
          >
            <el-input v-model="qutaForm.quta_accmtrl_list[scope.$index].mtrl_name" maxlength="30" show-word-limit clearable> </el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="规格" align="center" prop="mtrl_spec" min-width="140">
        <template slot="header">
          <span style="color: red">规格</span>
        </template>
        <template slot-scope="scope">
          <el-form-item
            label-width="0"
            :prop="'quta_accmtrl_list.' + scope.$index + '.mtrl_spec'"
            :rules="{ required: true, message: ' ', trigger: 'blur' }"
          >
            <el-input v-model="qutaForm.quta_accmtrl_list[scope.$index].mtrl_spec" maxlength="30" show-word-limit clearable> </el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <!-- <el-table-column label="系数" align="center" prop="quta_mtrl_lossrate" min-width="140">
        <template slot="header">
          <span style="color:red">系数</span>
        </template>
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'quta_accmtrl_list.' + scope.$index + '.quta_mtrl_lossrate'"
            :rules="{ required: true, message: ' ', trigger: 'blur' }">
            <el-input v-model="qutaForm.quta_accmtrl_list[scope.$index].quta_mtrl_lossrate" maxlength="15" clearable
              show-word-limit
              @input="
              qutaForm.quta_accmtrl_list[scope.$index].quta_mtrl_lossrate = qutaForm.quta_accmtrl_list[scope.$index].quta_mtrl_lossrate.replace(/^0+(\d)/, '$1').replace(/^\./, '0.').match(/^\d*(\.?\d{0,4})/g)[0] || ''"
              @change="qutaForm.quta_accmtrl_list[scope.$index].quta_mtrl_lossrate = helper.calcPrice(qutaForm.quta_accmtrl_list[scope.$index].quta_mtrl_lossrate, 4, 10000000000)">
            </el-input>
          </el-form-item>
        </template>
      </el-table-column> -->
      <el-table-column label="用量" align="center" prop="quta_mtrl_consume" min-width="140">
        <template slot="header">
          <span style="color: red">用量</span>
        </template>
        <template slot-scope="scope">
          <el-form-item
            label-width="0"
            :prop="'quta_accmtrl_list.' + scope.$index + '.quta_mtrl_consume'"
            :rules="{ required: true, message: ' ', trigger: 'blur' }"
          >
            <el-input
              v-model="qutaForm.quta_accmtrl_list[scope.$index].quta_mtrl_consume"
              maxlength="15"
              clearable
              show-word-limit
              @input="
                qutaForm.quta_accmtrl_list[scope.$index].quta_mtrl_consume =
                  qutaForm.quta_accmtrl_list[scope.$index].quta_mtrl_consume
                    .replace(/^0+(\d)/, '$1')
                    .replace(/^\./, '0.')
                    .match(/^\d*(\.?\d{0,4})/g)[0] || ''
              "
              @change="mtrlConsumeChange(scope.$index)"
            >
            </el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="耗损比率" align="center" prop="quta_mtrl_lossrate" min-width="140">
        <template slot="header">
          <span style="color: red">损耗比率</span>
        </template>
        <template slot-scope="scope">
          <el-form-item
            label-width="0"
            :prop="'quta_accmtrl_list.' + scope.$index + '.quta_mtrl_lossrate'"
            :rules="{ required: true, message: ' ', trigger: 'blur' }"
          >
            <el-input
              v-model="qutaForm.quta_accmtrl_list[scope.$index].quta_mtrl_lossrate"
              maxlength="15"
              clearable
              show-word-limit
              @input="
                qutaForm.quta_accmtrl_list[scope.$index].quta_mtrl_lossrate =
                  qutaForm.quta_accmtrl_list[scope.$index].quta_mtrl_lossrate
                    .replace(/^0+(\d)/, '$1')
                    .replace(/^\./, '0.')
                    .match(/^\d*(\.?\d{0,4})/g)[0] || ''
              "
              @change="mtrlLossrateChange(scope.$index)"
            >
            </el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="单价" align="center" prop="mtrl_price" min-width="140">
        <template slot="header">
          <span style="color: red">单价</span>
        </template>
        <template slot-scope="scope">
          <el-form-item
            label-width="0"
            :prop="'quta_accmtrl_list.' + scope.$index + '.mtrl_price'"
            :rules="{ required: true, message: ' ', trigger: 'blur' }"
          >
            <el-input
              v-model="qutaForm.quta_accmtrl_list[scope.$index].mtrl_price"
              maxlength="15"
              show-word-limit
              clearable
              @change="getTotalPrice"
              @input="
                qutaForm.quta_accmtrl_list[scope.$index].mtrl_price =
                  qutaForm.quta_accmtrl_list[scope.$index].mtrl_price
                    .replace(/^0+(\d)/, '$1')
                    .replace(/^\./, '0.')
                    .match(/^\d*(\.?\d{0,4})/g)[0] || ''
              "
              @blur="
                qutaForm.quta_accmtrl_list[scope.$index].mtrl_price = helper.calcPrice(
                  qutaForm.quta_accmtrl_list[scope.$index].mtrl_price,
                  4,
                  10000000000
                )
              "
            >
            </el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="金额" align="right" show-overflow-tooltip>
        <template slot-scope="scope">
          <div v-if="totalShow">
            {{ qutaForm.quta_accmtrl_list[scope.$index].mtrl_total_price }}
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="vd_price_toatl">
      <div class="vd_price">
        <h3><span>总计:</span></h3>
        <h2 class="vg_ml_8" style="color: #c70e0e">
          <span>{{ totalPrice }}</span>
        </h2>
      </div>
    </div>
  </div>
</template>

<script>
import { BigNumber } from 'bignumber.js';

export default {
  name: 'QutaAuxiliary',
  props: {
    qutaForm: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dataList: [{}, {}, {}, {}, {}, {}],
      printingFilmPrice: 0,
      cartonPrice: 0,
      bagPrkgPrice: 0,
      emcdTotalPrice: 0,
      freightPrice: 0,
      totalPrice: 0,
      totalShow: true,
      multipleSelection: []
    };
  },
  created() {
    this.initData();
  },
  mounted() {},
  computed: {},
  watch: {
    'qutaForm.smpl_frei_flag'(newVal) {
      // this.getTotalPrice()
    }
  },
  methods: {
    initData() {},
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 计算运费
    freightPriceChange() {
      let num1 = new BigNumber(1);
      num1 = num1
        .times(this.qutaForm.smpl_box_l)
        .div(100)
        .times(this.qutaForm.smpl_box_w)
        .div(100)
        .times(this.qutaForm.smpl_box_h)
        .div(100)
        .times(this.qutaForm.smpl_freight)
        .div(this.qutaForm.smpl_qpc)
        .toNumber();
      if (Number.isFinite(num1)) {
        this.freightPrice = this.helper.haveFour(num1);
      } else {
        this.freightPrice = '0.0000';
      }
      this.getTotalPrice();
    },
    // 计算电绣金额
    emcdTotalPriceChnage() {
      let num1 = new BigNumber(1); //针数
      let num2 = new BigNumber(1); //片数
      let num3 = new BigNumber(1); //贴数
      let num4 = new BigNumber(0);
      if (Number(this.qutaForm.quta_emcd_hnum) % 1000 !== 0) {
        num1 = num1.times(this.qutaForm.quta_emcd_hnum).idiv(1000).plus(1);
      } else {
        num1 = num1.times(this.qutaForm.quta_emcd_hnum).div(1000);
      }
      num1 = num1.times(1000).times(this.qutaForm.quta_emcd_hpru).toNumber();
      num2 = num2.times(this.qutaForm.quta_emcd_num).times(this.qutaForm.quta_emcd_pru).toNumber();
      num3 = num3.times(this.qutaForm.quta_emcd_pnum).times(this.qutaForm.quta_emcd_ppru).toNumber();
      if (num1 >= num2) {
        num4 = num4.plus(num3).plus(num1).toNumber();
      } else {
        num4 = num4.plus(num3).plus(num2).toNumber();
      }
      if (Number.isFinite(num4)) {
        this.qutaForm.quta_emcd_price = this.helper.haveFour(num4);
      } else {
        this.qutaForm.quta_emcd_price = 0;
      }
      this.getTotalPrice();
    },
    // 计算塑料袋金额
    emcdNumPriceChange() {
      if (Number(this.qutaForm.smpl_bag_l) > 0 && Number(this.qutaForm.smpl_bag_w) > 0) {
        let num1 = new BigNumber(1);
        num1 = num1.times(this.qutaForm.smpl_bag_l).div(100).times(this.qutaForm.smpl_bag_w).div(100).times(0.08);
        let num2 = new BigNumber(1);
        num2 = num2.times(num1).times(this.qutaForm.quta_bag_prkg).div(this.qutaForm.smpl_qpb).toNumber();
        if (Number.isFinite(num2)) {
          this.bagPrkgPrice = this.helper.haveFour(num2);
        } else {
          this.bagPrkgPrice = '0.0000';
        }
      } else {
        this.bagPrkgPrice = '0.0000';
      }
      this.getTotalPrice();
    },
    // 计算纸箱金额
    cartonPriceChange() {
      if (Number(this.qutaForm.smpl_box_l) > 0 && Number(this.qutaForm.smpl_box_w) > 0 && Number(this.qutaForm.smpl_box_h)) {
        let arr1 = [this.qutaForm.smpl_box_l, this.qutaForm.smpl_box_w, 8];
        let arr2 = [this.qutaForm.smpl_box_h, this.qutaForm.smpl_box_w, 4];
        let num1 = new BigNumber(0);
        num1 = BigNumber.sum(...arr1)
          .div(100)
          .toString();
        let num2 = new BigNumber(0);
        num2 = BigNumber.sum(...arr2)
          .div(100)
          .toString();
        let num3 = new BigNumber(1);
        num3 = num3.times(num1).times(num2).times(this.qutaForm.quta_ctn_prsm).div(this.qutaForm.smpl_qpc).toNumber();
        if (Number.isFinite(num3)) {
          this.cartonPrice = this.helper.haveFour(num3);
        } else {
          this.cartonPrice = '0.0000';
        }
      } else {
        this.cartonPrice = '0.0000';
      }
      this.getTotalPrice();
    },
    // 计算印片
    printingFilmPriceChange() {
      let num = new BigNumber(1);
      num = num.times(this.qutaForm.quta_stam_num).times(this.qutaForm.quta_stam_pru);
      this.printingFilmPrice = this.helper.haveFour(num);
      this.getTotalPrice();
    },
    getTotalPrice() {
      setTimeout(() => {
        this.totalShow = false;
        let num = new BigNumber(0);
        let num1 = new BigNumber(0);
        num = num.plus(this.printingFilmPrice).plus(this.cartonPrice).plus(this.bagPrkgPrice).plus(this.qutaForm.quta_emcd_price);
        if (this.qutaForm.smpl_frei_flag !== '0') {
          num = num.plus(this.freightPrice);
        }
        for (let i = 0; i < this.qutaForm.quta_accmtrl_list.length; i++) {
          if (
            Number(this.qutaForm.quta_accmtrl_list[i].quta_mtrl_consume) > 0 &&
            Number(this.qutaForm.quta_accmtrl_list[i].mtrl_price) > 0
          ) {
            let temp = new BigNumber(1);
            temp = temp
              .times(this.qutaForm.quta_accmtrl_list[i].quta_mtrl_consume)
              .times(this.qutaForm.quta_accmtrl_list[i].mtrl_price)
              .times(this.qutaForm.quta_accmtrl_list[i].quta_mtrl_lossrate);
            num1 = num1.plus(temp);
            this.$set(this.qutaForm.quta_accmtrl_list[i], 'mtrl_total_price', this.helper.haveFour(temp));
          } else {
            this.$set(this.qutaForm.quta_accmtrl_list[i], 'mtrl_total_price', '0.0000');
          }
        }
        num = num.plus(num1).toNumber();
        this.totalPrice = this.helper.haveFour(num);
        this.$emit('getTotalPrice', this.totalPrice);
        this.totalShow = true;
      }, 500);
    },
    // 新增其他
    addQutaAccmtrl() {
      let item = {
        mtrl_name: null,
        mtrl_spec: null,
        quta_mtrl_consume: 1.0,
        quta_mtrl_lossrate: 1.0,
        mtrl_price: null,
        mtrl_id: 0,
        smpl_mtrl_id: 0,
        mtrl_type: 1,
        mtrl_no: '',
        key: this.qutaForm.quta_accmtrl_list.length
      };
      this.$set(this.qutaForm.quta_accmtrl_list, this.qutaForm.quta_accmtrl_list.length, item);
    },
    // 删除
    delQutaAccmtrl() {
      let temp = [];
      for (let i = 0; i < this.multipleSelection.length; i++) {
        for (let j = 0; j < this.qutaForm.quta_accmtrl_list.length; j++) {
          if (this.qutaForm.quta_accmtrl_list[j].key === this.multipleSelection[i].key) {
            temp.push(j);
          }
        }
      }
      temp = temp.sort((a, b) => {
        return b - a;
      });
      for (let j of temp) {
        this.qutaForm.quta_accmtrl_list.splice(j, 1);
      }
      for (let j = 0; j < this.qutaForm.quta_accmtrl_list.length; j++) {
        this.qutaForm.quta_accmtrl_list[j].key = Number(j);
      }
      this.getTotalPrice();
    },
    mtrlConsumeChange(index) {
      if (!this.qutaForm.quta_accmtrl_list[index].quta_mtrl_consume) {
        this.qutaForm.quta_accmtrl_list[index].quta_mtrl_consume = this.helper.calcPrice(1, 4, 10000000000);
      } else {
        this.qutaForm.quta_accmtrl_list[index].quta_mtrl_consume = this.helper.calcPrice(
          this.qutaForm.quta_accmtrl_list[index].quta_mtrl_consume,
          4,
          10000000000
        );
      }
      this.getTotalPrice();
    },
    mtrlLossrateChange(index) {
      if (!this.qutaForm.quta_accmtrl_list[index].quta_mtrl_lossrate) {
        this.qutaForm.quta_accmtrl_list[index].quta_mtrl_lossrate = this.helper.calcPrice(1, 4, 10000000000);
      } else {
        this.qutaForm.quta_accmtrl_list[index].quta_mtrl_lossrate = this.helper.calcPrice(
          this.qutaForm.quta_accmtrl_list[index].quta_mtrl_lossrate,
          4,
          10000000000
        );
      }
      this.getTotalPrice();
    }
  }
};
</script>

<style scoped lang="scss">
.vd_price_toatl {
  /* display: flex;
  justify-content: end; */
  padding: 10px 20px;
  text-align: right;
}

::v-deep .inpRig {
  > input {
    text-align: right;
  }
}

.vd_price {
  display: flex;
  justify-content: flex-end;
}
.vd_dis {
  display: flex;
}
.vd_lab_wid {
  text-align: right;
  padding-right: 10px;
}
.vd_lab_wid_o {
  width: 180px;
  text-align: right;
  padding-right: 10px;
}
</style>
